module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-102050548-36","head":false,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"plato-centre","short_name":"plato","start_url":"/","background_color":"#F8F1ED","theme_color":"#7E2F19","display":"minimal-ui","icon":"static/plato_centre_logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"411e7685cf96338c5c643169047f8733"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
